<template>
    <div class="dashbroad">
        <div class="area">
            <OrderStatistics style="flex: 2" />
            <HotTimeStatistics style="flex: 1" />
            <div class="flex" style="flex: 1; flex-direction: column">
                <TimeSelect />
                <ActivityStatistics style="flex: 1" />
                <CityStatistics style="flex: 1" />
            </div>
        </div>
        <OderRates />
        <div class="area">
            <ProviderResTime style="flex: 2" />
            <OrderCancelStatistics style="flex: 3" />
        </div>
        <div class="area">
            <PlatformStatistic style="flex: 2" />
            <div class="flex" style="flex: 1; flex-direction: column">
                <PriceStatistics style="flex: 1" />
                <UserStatistic style="flex: 1" />
            </div>
        </div>
        <div class="area">
            <CustomerStatistics style="flex: 1" />
            <CustomerTypeStatistics style="flex: 1" />
            <CustomerSeverityStatistics style="flex: 1" />
        </div>
    </div>
</template>

<script>
import OrderStatistics from "./components/OrderStatistics.vue";
import HotTimeStatistics from "./components/HotTimeStatistics2.vue";
import TimeSelect from "./components/TimeSelect";
import ActivityStatistics from "./components/ActivityStatistics";
import CityStatistics from "./components/CityStatistics.vue";
import OderRates from "./components/OderRates.vue";
import OrderCancelStatistics from "./components/OrderCancelStatistics";
import ProviderResTime from "./components/ProviderResTime";
import PlatformStatistic from "./components/PlatformStatistic";
import PriceStatistics from "./components/PriceStatistics";
import UserStatistic from "./components/UserStatistic";
import CustomerStatistics from "./components/CustomerStatistics";
import CustomerSeverityStatistics from "./components/CustomerSeverityStatistics";
import CustomerTypeStatistics from "./components/CustomerTypeStatistics";

export default {
    name: "DataDashboard",
    components: {
        OrderStatistics,
        HotTimeStatistics,
        ActivityStatistics,
        CityStatistics,
        OderRates,
        OrderCancelStatistics,
        ProviderResTime,
        PlatformStatistic,
        PriceStatistics,
        UserStatistic,
        CustomerStatistics,
        CustomerSeverityStatistics,
        CustomerTypeStatistics,
        TimeSelect,
    },

    data() {
        return {
            option: {
                title: {
                    text: "活動項目佔比",
                    left: "center",
                },
                color: ["rgba(255,87,51,.3)", "rgba(255,87,51,.6)", "rgba(255,87,51,1)"],
                tooltip: {
                    trigger: "item",
                    formatter: "{a} <br/>{b} : {c} ({d}%)",
                },
                legend: {
                    orient: "vertical",
                    left: "left",
                    data: ["吃喝玩樂", "飲酒享樂", "雲遊漫旅"],
                },
                series: [
                    {
                        name: "活動項目佔比",
                        type: "pie",
                        radius: "55%",
                        center: ["60%", "60%"],
                        data: [
                            { value: 335, name: "吃喝玩樂" },
                            { value: 310, name: "飲酒享樂" },
                            { value: 234, name: "雲遊漫旅" },
                        ],
                        emphasis: {
                            itemStyle: {
                                shadowBlur: 10,
                                shadowOffsetX: 0,
                                shadowColor: "rgba(0, 0, 0, 0.5)",
                            },
                        },
                    },
                ],
            },
            option2: {
                title: {
                    text: "會面城市佔比",
                    left: "center",
                },
                color: ["rgb(211,126,107)", "rgba(246,178,107)", "rgba(255,217,102)", "rgb(147,196,125)", "rgb(109,158,235)", "rgb(142,124,195)"],
                tooltip: {
                    trigger: "item",
                    formatter: "{a} <br/>{b} : {c} ({d}%)",
                },
                legend: {
                    orient: "vertical",
                    left: "left",
                    data: ["台北", "桃竹", "台中", "雲嘉南", "高雄", "花東"],
                },
                series: [
                    {
                        name: "會面城市佔比",
                        type: "pie",
                        radius: "55%",
                        center: ["60%", "60%"],
                        data: [
                            { value: 335, name: "台北" },
                            { value: 310, name: "桃竹" },
                            { value: 234, name: "台中" },
                            { value: 335, name: "雲嘉南" },
                            { value: 310, name: "高雄" },
                            { value: 234, name: "花東" },
                        ],
                        emphasis: {
                            itemStyle: {
                                shadowBlur: 10,
                                shadowOffsetX: 0,
                                shadowColor: "rgba(0, 0, 0, 0.2)",
                            },
                        },
                    },
                ],
            },
            option3: {
                title: {
                    text: "完成訂單數",
                },
                tooltip: {
                    trigger: "axis",
                },

                grid: {
                    left: "3%",
                    right: "4%",
                    bottom: "3%",
                    containLabel: true,
                },
                xAxis: {
                    type: "category",
                    boundaryGap: false,
                    data: ["21", "22", "23", "24", "25", "26", "27"],
                },
                yAxis: {
                    type: "value",
                },
                series: [
                    {
                        name: "完成訂單數",
                        type: "line",
                        data: [5, 10, 15, 4, 3, 20, 4],
                    },
                ],
            },
            option4: {
                title: {
                    text: "預訂取消趨勢",
                },
                tooltip: {
                    trigger: "axis",
                },
                legend: {
                    orient: "vertical",
                    left: "right",
                    data: ["自動婉拒（狀態改變）", "自動婉拒（未回覆）", "服務商婉拒", "會員取消", "會員臨時取消"],
                },
                grid: {
                    left: "3%",
                    right: "20%",
                    bottom: "3%",
                    containLabel: true,
                },
                xAxis: {
                    type: "category",
                    data: ["11", "12", "13", "14", "15", "16", "17"],
                },
                yAxis: {
                    type: "value",
                },
                series: [
                    {
                        name: "自動婉拒（狀態改變）",
                        type: "line",
                        data: [3, 4, 5, 6, 7, 8, 9],
                    },
                    {
                        name: "自動婉拒（未回覆）",
                        type: "line",
                        data: [6, 7, 10, 1, 0, 2, 8],
                    },
                    {
                        name: "服務商婉拒",
                        type: "line",
                        data: [10, 10, 1, 1, 10, 1, 7],
                    },
                    {
                        name: "會員取消",
                        type: "line",
                        data: [4, 3, 4, 11, 2, 4, 6],
                    },
                    {
                        name: "會員臨時取消",
                        type: "line",
                        data: [3, 6, 8, 9, 12, 1, 15],
                    },
                ],
            },
        };
    },
};
</script>

<style lang="scss" scoped>
.chart {
    height: 200px;
}

.dashbroad {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    padding: 10px;
    .item {
        // width: 45%;
        background: #fff;
        border-radius: 5px;
        margin: 10px 5px;
        padding: 10px;
        .chart-out {
            width: 100%;
        }
    }

    .area {
        width: 100%;
        display: flex;
        margin: 5px;
    }
}
</style>
