<template>
    <div class="card">
        <div class="top">
            <div class="title">30 天內服務商平均回應時間排名</div>
            <div class="switch">
                <span>最長</span>
                <el-switch v-model="value" :width="50" active-color="#ff4949" inactive-color="#ff4949"> </el-switch>
                <span>最短</span>
            </div>
        </div>
        <div class="bottom">
            <div class="items">
                <div class="item">
                    名字有十個字限制喔喔
                    <span> <span class="text-orange">10</span> / 1</span>
                </div>
                <div class="item">
                    名字有十個字限制喔喔
                    <span> <span class="text-orange">10</span> / 1</span>
                </div>
                <div class="item">
                    名字有十個字限制喔喔
                    <span> <span class="text-orange">10</span> / 1</span>
                </div>
                <div class="item">
                    名字有十個字限制喔喔
                    <span> <span class="text-orange">10</span> / 1</span>
                </div>
                <div class="item">
                    名字有十個字限制喔喔
                    <span> <span class="text-orange">10</span> / 1</span>
                </div>
            </div>
            <div class="items">
                <div class="item">
                    名字有十個字限制喔喔
                    <span> <span class="text-orange">10</span> / 1</span>
                </div>
                <div class="item">
                    名字有十個字限制喔喔
                    <span> <span class="text-orange">10</span> / 1</span>
                </div>
                <div class="item">
                    名字有十個字限制喔喔
                    <span> <span class="text-orange">10</span> / 1</span>
                </div>
                <div class="item">
                    名字有十個字限制喔喔
                    <span> <span class="text-orange">10</span> / 1</span>
                </div>
                <div class="item">
                    名字有十個字限制喔喔
                    <span> <span class="text-orange">10</span> / 1</span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "ProviderResTime",
    data() {
        return {
            value: false,
        };
    },
};
</script>

<style lang="scss" scoped>
.card {
    background: #fff;
    border-radius: 5px;
    margin: 0px 5px;
    padding: 0 0px;
    .top {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 30px 30px 20px 30px;
        .title {
            font-size: 14px;
            font-weight: 700;
        }
        .switch {
            display: flex;
            align-items: center;
            span {
                margin: 0 5px;
                font-size: 12px;
            }
        }
    }
    .bottom {
        display: flex;
        padding: 0 20px;
        .items {
            flex: 1;
            margin: 0 5px;
            .item {
                width: 100%;
                text-align: left;
                display: flex;
                justify-content: space-between;
                font-size: 14px;
                padding: 10px;
                margin-top: 5px;
                border-bottom: 1px solid #ccc;
            }
        }
    }

    .text-orange {
        color: rgb(255, 87, 51);
    }
}
</style>
