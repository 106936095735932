<template>
    <div class="select" @click="active = !active">
        {{ labelActived }} <i class="fas fa-chevron-down ml-2" style="font-size: 12px"></i>
        <div v-if="active" class="select-down shadow-lg">
            <div v-for="item in selectOptions" :key="item.val" class="item" :class="{ focus: item.val == val }" @click.stop="choose(item.val)">
                {{ item.label }}
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "TimeSelect",
    computed: {
        labelActived() {
            const idx = this.selectOptions.findIndex((i) => i.val == this.val);
            return this.selectOptions[idx].label;
        },
    },
    data() {
        return {
            active: false,
            val: "last 7 days",
            selectOptions: [
                {
                    label: "過去 7 天",
                    val: "last 7 days",
                },
                {
                    label: "過去 30 天",
                    val: "last 30 days",
                },
                {
                    label: "過去 90 天",
                    val: "last 90 days",
                },
                {
                    label: "本週",
                    val: "week",
                },
                {
                    label: "前週",
                    val: "last week",
                },
                {
                    label: "本月",
                    val: "month",
                },
                {
                    label: "前月",
                    val: "last month",
                },
                {
                    label: "本季",
                    val: "season",
                },
                {
                    label: "前季",
                    val: "last season",
                },
                {
                    label: "今年",
                    val: "year",
                },
                {
                    label: "去年",
                    val: "last year",
                },
            ],
        };
    },
    methods: {
        choose(val) {
            this.val = val;
            this.active = false;
        },
    },
};
</script>

<style lang="scss" scoped>
.select {
    background: rgb(255, 204, 193);
    border-radius: 5px;
    margin: 0 5px 5px 5px;
    padding: 8px 15px;
    font-size: 14px;
    font-weight: 500;
    cursor: pointer;
    position: relative;
    i {
        font-size: 12px;
        margin-left: 10px;
    }

    .select-down {
        background: #fff;
        position: absolute;
        z-index: 1000;
        width: 100%;
        left: 0%;
        top: 36px;
        border-radius: 0 0 5px 5px;
        .item {
            padding: 7px 15px;
            font-size: 12px;
            color: rgb(44, 44, 44);
            &:hover {
                background: rgba(255, 86, 50, 0.1);
            }
            &.focus {
                background: rgba(255, 86, 50, 0.1);
            }
        }
    }
}
</style>
