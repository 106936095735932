<template>
    <div class="card">
        <v-chart v-if="true" style="width: 100%" class="chart" :option="option" />
    </div>
</template>

<script>
import { use } from "echarts/core";
import { CanvasRenderer } from "echarts/renderers";
import { PieChart } from "echarts/charts";
import { TitleComponent, TooltipComponent, LegendComponent } from "echarts/components";
import VChart, { THEME_KEY } from "vue-echarts";

import { ToolboxComponent, GridComponent } from "echarts/components";
import { LineChart } from "echarts/charts";
import { UniversalTransition } from "echarts/features";

use([CanvasRenderer, PieChart, TitleComponent, ToolboxComponent, TooltipComponent, GridComponent, LineChart, LegendComponent, UniversalTransition]);

export default {
    name: "CustomerTypeStatistics",
    components: {
        VChart,
    },
    provide: {
        [THEME_KEY]: "",
    },
    data() {
        return {
            option: {
                title: {
                    textStyle: {
                        fontSize: "14px",
                    },
                    text: "客訴單分類佔比",
                    left: "20px",
                    top: "25px",
                },
                color: ["rgb(211,126,107)", "rgba(246,178,107)", "rgba(255,217,102)", "rgb(147,196,125)", "rgb(109,158,235)", "rgb(142,124,195)"],
                tooltip: {
                    trigger: "item",
                    formatter: "{a} <br/>{b} : {c} ({d}%)",
                },
                legend: {
                    orient: "vertical",
                    left: "20px",
                    top: "55px",
                    itemWidth: 10,
                    itemHeight: 10,
                    data: ["預訂相關", "會員儲值相關", "服務商款項相關", "平台相關", "用戶帳號相關", "暴力事件相關"],
                    textStyle: {
                        fontSize: 10,
                    },
                },
                series: [
                    {
                        name: "客訴單分類佔比",
                        type: "pie",
                        radius: "70%",
                        center: ["70%", "50%"],
                        label: {
                            show: false,
                        },
                        data: [
                            { value: 335, name: "預訂相關" },
                            { value: 310, name: "會員儲值相關" },
                            { value: 234, name: "服務商款項相關" },
                            { value: 335, name: "平台相關" },
                            { value: 310, name: "用戶帳號相關" },
                            { value: 234, name: "暴力事件相關" },
                        ],
                        emphasis: {
                            itemStyle: {
                                shadowBlur: 10,
                                shadowOffsetX: 0,
                                shadowColor: "rgba(0, 0, 0, 0.5)",
                            },
                        },
                    },
                ],
            },
        };
    },
};
</script>

<style lang="scss" scoped>
.card {
    background: #fff;
    height: 164px;
    border-radius: 5px;
    margin: 5px 5px;
    margin-bottom: 0;
}
</style>
