<template>
    <div class="card">
        <v-chart style="width: 100%" class="chart" :option="option" />
    </div>
</template>

<script>
import { use } from "echarts/core";
import { CanvasRenderer } from "echarts/renderers";
import { PieChart } from "echarts/charts";
import { TitleComponent, TooltipComponent, LegendComponent } from "echarts/components";
import VChart, { THEME_KEY } from "vue-echarts";

import { ToolboxComponent, GridComponent } from "echarts/components";
import { LineChart } from "echarts/charts";
import { UniversalTransition } from "echarts/features";

use([CanvasRenderer, PieChart, TitleComponent, ToolboxComponent, TooltipComponent, GridComponent, LineChart, LegendComponent, UniversalTransition]);

export default {
    name: "ActivityStatistics",
    components: {
        VChart,
    },
    provide: {
        [THEME_KEY]: "",
    },
    data() {
        return {
            option: {
                title: {
                    textStyle: {
                        fontSize: "14px",
                    },
                    text: "活動項目佔比",
                    left: "20px",
                    top: "10px",
                },
                color: ["rgba(255,87,51,.3)", "rgba(255,87,51,.6)", "rgba(255,87,51,1)"],
                tooltip: {
                    trigger: "item",
                    formatter: "{a} <br/>{b} : {c} ({d}%)",
                },
                legend: {
                    orient: "vertical",
                    left: "20px",
                    top: "50px",
                    itemWidth: 10,
                    itemHeight: 10,
                    data: ["吃喝玩樂", "飲酒享樂", "雲遊漫旅"],
                    textStyle: {
                        fontSize: 12,
                    },
                },
                series: [
                    {
                        name: "活動項目佔比",
                        type: "pie",
                        radius: "70%",
                        center: ["70%", "50%"],
                        label: {
                            show: false,
                        },
                        data: [
                            { value: 335, name: "吃喝玩樂" },
                            { value: 310, name: "飲酒享樂" },
                            { value: 234, name: "雲遊漫旅" },
                        ],
                        emphasis: {
                            itemStyle: {
                                shadowBlur: 10,
                                shadowOffsetX: 0,
                                shadowColor: "rgba(0, 0, 0, 0.5)",
                            },
                        },
                    },
                ],
            },
        };
    },
};
</script>

<style lang="scss" scoped>
.card {
    background: #fff;
    border-radius: 5px;
    margin: 0px 5px;
    padding: 0 0px;
    height: 329px;
}
</style>
