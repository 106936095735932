<template>
    <div class="card">
        <div class="title">熱門預訂時段</div>
        <apexchart type="heatmap" height="350" :options="chartOptions" :series="series"></apexchart>
    </div>
</template>

<script>
import vue from "vue";

import VueApexCharts from "vue-apexcharts";
vue.use(VueApexCharts);

export default {
    name: "HotTimeStatistics",
    components: {
        apexchart: VueApexCharts,
    },

    data() {
        return {
            series: [
                {
                    name: "02 - 06",
                    data: [8, 4, 1, 6, 6, 1, 10],
                },
                {
                    name: "06 - 10",
                    data: [9, 1, 1, 1, 11, 3, 10],
                },
                {
                    name: "10 - 14",
                    data: [3, 1, 2, 4, 11, 14, 10],
                },
                {
                    name: "14 - 18",
                    data: [0, 1, 1, 1, 3, 12, 9],
                },
                {
                    name: "18 - 22",
                    data: [0, 1, 4, 1, 2, 14, 40],
                },
                {
                    name: "22 - 02",
                    data: [0, 1, 1, 1, 6, 13, 18],
                },
            ],
            chartOptions: {
                chart: {
                    width: "100%",
                    height: 200,
                    bottom: "20%",
                    type: "heatmap",
                    toolbar: {
                        show: false,
                    },
                },
                xaxis: {
                    type: "category",
                    categories: ["一", "二", "三", "四", "五", "六", "日"],
                },
                yaxis: {
                    labels: {
                        align: "right",
                        borderColor: "#c2c2c2",
                        borderWidth: 1,
                        borderRadius: 2,
                    },
                    opposite: true,
                },
                stroke: {
                    width: 1,
                },
                plotOptions: {
                    heatmap: {
                        shadeIntensity: 0,
                        radius: 0,
                        useFillColorAsStroke: false,
                        colorScale: {
                            ranges: [
                                {
                                    from: 0,
                                    to: 3,
                                    name: "1 - 3",
                                    color: "#ffccc1",
                                },
                                {
                                    from: 3,
                                    to: 6,
                                    name: "3 - 6",
                                    color: "#ff9a84",
                                },
                                {
                                    from: 6,
                                    to: 10000,
                                    name: " > 6",
                                    color: "#ff5733",
                                },
                            ],
                        },
                    },
                },
                dataLabels: {
                    enabled: true,
                },

                grid: {
                    show: false,
                    padding: {
                        right: 10,
                        bottom: 60,
                    },
                },
            },
        };
    },
};
</script>

<style lang="scss" scoped>
.card {
    .title {
        font-size: 14px;
        font-weight: 700;
    }
    background: #fff;
    border-radius: 5px;
    margin: 0 5px;
    padding: 20px;
    height: 340px;
    display: flex;
    flex-direction: column;
    .chart {
        padding: 10px;
    }
}
</style>
