<template>
    <div class="card">
        <div class="title">新增客訴單統計</div>
        <div class="rates">
            <div class="rate">
                <div class="val">0 <span>件</span></div>
            </div>
            <div class="rate">
                <div class="label">相較前期增加 <span class="text-orange"> -100 </span> %</div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "CustomerStatistics",
};
</script>

<style lang="scss" scoped>
.card {
    background: #fff;
    border-radius: 5px;
    margin: 5px;
    padding: 30px 30px 20px 30px;
    display: flex;
    flex-direction: column;
    height: 164px;
    .title {
        font-size: 14px;
        font-weight: 700;
    }
    .rates {
        width: 100%;
        flex: 1;
        margin-top: 10px;
        display: flex;
        align-items: center;
        .rate {
            flex: 1;
            justify-content: center;
            text-align: left;
            .val {
                font-size: 30px;
                margin-bottom: 5px;
                span {
                    font-size: 14px;
                }
            }
            .label {
                font-size: 14px;
            }
        }
    }
}

.text-orange {
    color: rgb(255, 87, 51);
}
</style>
