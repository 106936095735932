<template>
    <div class="card">
        <div class="title">訂單統計</div>
        <div class="rates">
            <div class="rate">
                <div class="val">65 %</div>
                <div class="label">預訂成功率</div>
            </div>
            <div class="rate">
                <div class="val">7 %</div>
                <div class="label">主動取消率</div>
            </div>
            <div class="rate">
                <div class="val">28 %</div>
                <div class="label">被動取消率</div>
            </div>
            <div class="rate">
                <div class="val">3.8 / 50</div>
                <div class="label">評分平均</div>
            </div>
        </div>
    </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.card {
    background: #fff;
    border-radius: 5px;
    margin: 10px;
    .title {
        font-size: 14px;
        font-weight: 700;
        margin: 30px 30px 20px 30px;
    }
    .rates {
        width: 100%;
        display: flex;
        .rate {
            flex: 1;
            justify-content: center;
            text-align: center;
            .val {
                font-size: 30px;
                margin-bottom: 5px;
            }
            .label {
                font-size: 14px;
            }
        }
    }
}
</style>
